import React from "react";
import config from "../core/config/config";
import domainRouter from "./domainRouter";
import MainRoutes from "./MainRoutes";
import CodeRoutes from "./CodeRoutes";
import CorporativeRoutes from "./CorporativeRoutes";
import PromoRoutes from "./PromoRoutes";
import { YMInitializer } from 'react-yandex-metrika';

interface IProps {
  className?: string;
}

const SCREENS = {
  [config.giftRoute]: MainRoutes,
  [config.codeRoute]: CodeRoutes,
  [config.corpRoute]: CorporativeRoutes,
  [config.promoRoute]: PromoRoutes,
}

const YM_ACCOUNTS = {
  'podarok.prostoapp.ru': [86989752],
  // 'prosto-meditation.ru': [94588675],
  'corp.prostoapp.ru': [92179976],
}

const DomainRoutes: React.FC<IProps> = ({ className }) => {
  const Screen = SCREENS[domainRouter.currentHost];
  /* @ts-ignore */
  const yandexMetrikaCounters = YM_ACCOUNTS[domainRouter.currentHost] || [];
  // console.log('yandexMetrikaCounters', yandexMetrikaCounters)
  // console.log('domainRouter.currentHost', domainRouter.currentHost)
  return (
    <div className={className}>
      <YMInitializer accounts={yandexMetrikaCounters} options={{ webvisor: true, trackHash: true }} version="2" />
      {/* {domainRouter.currentHost === 'podarok.prostoapp.ru' &&
        <YMInitializer accounts={[86989752]} options={{ webvisor: true, trackHash: true }} version="2" />
      }
      {domainRouter.currentHost === 'prosto-meditation.ru' &&
        <YMInitializer accounts={[94588675]} options={{ webvisor: true, trackHash: true }} version="2" />
      } */}
      {Screen ? <Screen /> : <MainRoutes />}
    </div>
  );
};

export default DomainRoutes;
