import React, { useCallback, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import UpperMainBlock from '../../components/UpperMainBlock/UpperMainBlock';
import ym from 'react-yandex-metrika';
import { IMainScreenState } from "../../types/IMainScreenState";
import { useHistory } from 'react-router-dom';
import debounce from "lodash.debounce";
import BuySubscriptionController from '../../core/buySubscription/controller';

const controller = new BuySubscriptionController();

const BuySubscription: React.FC = () => {
  const [screenState, setScreenState] = useState<IMainScreenState>({
    email: "",
    confirmEmail: false,
    promocodeId: null,
    text: "" || null,
    phone: "",
    months: 0,
    price: 0,
    discount: 0,
    canPay: false,
    paymentMethod: 'tinkoff',
  });
  const history = useHistory();

  const handleDataChange = useCallback((newState: IMainScreenState) => {
    setScreenState({
      ...newState
    });
  }, [setScreenState]);

  const handleBuyClick = useCallback(debounce(async () => {
    window.generalPixel.postClick('a2');
    const promocode = await controller.buySubscription(screenState.months, screenState.price, screenState.discount, screenState.phone);

    if (!promocode) {
      alert("Не получилось сгенерировать промокод! Попробуйте ещё раз.");
      return;
    }

    setScreenState({ ...screenState, promocodeId: promocode.id, text: promocode.text });
    ym('reachGoal', 'buy_certificate');
    history.push({
      pathname: '/activated',
      state: {
        promocodeId: promocode.id,
        text: promocode.text,
      },
    });
  }, 2000, { leading: true, trailing: false }), [screenState.phone, screenState.months, screenState.price])

  return (
    <>
      <UpperMainBlock data={screenState} onDataChange={handleDataChange} onBuyClick={handleBuyClick} promoCalculator={true}/>
      <Footer />
    </>
  );
}

export default BuySubscription;
