import React, { useCallback, useEffect, useState } from "react";
import UpperCorporateBlock from "../../components/UpperCorporateBlock/UpperCorporateBlock";
import BottomMainBlock from "../../components/BottomMainBlock/BottomMainBlock";
import Footer from "../../components/Footer/Footer";
import domainRouter from "../../navigation/domainRouter";
import config from "../../core/config/config";
import "./CorporateSubscription.css";
import { ICorporateScreenState } from "../../types/ICorporateScreenState";
import BuyCorporateSubscriptionController from "../../core/buyCorporateSubscription/controller";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
// import { withId } from 'react-yandex-metrika';

const controller = new BuyCorporateSubscriptionController();
const CorporateSubscription: React.FC = () => {
  // Corp
  const [screenState, setScreenState] = useState<ICorporateScreenState>({
    phone: "",
    email: "",
    subscriptionCount: 0,
    subscriptionDuration: 0,
    subscriptionPrice: 0,
    discount: 0,
    corporateDiscount: 0,
    promoCode: "",
    payMethod: "card",
    canPay: false,
    companyName: "",
    inn: "",
    workPhone: "",
    legalAddress: ""
  });

  const [screenStateCard, setScreenStateCard] = useState<ICorporateScreenState>({
    phone: "",
    email: "",
    subscriptionCount: 0,
    subscriptionDuration: 0,
    subscriptionPrice: 0,
    discount: 0,
    corporateDiscount: 0,
    promoCode: "",
    payMethod: "card",
    canPay: false,
  });

  const history = useHistory();

  const handleDataChange = useCallback((newState: ICorporateScreenState) => {
    setScreenState({
      ...newState,
      canPay: (isValidEmail(newState.email) &&
        isValidPhone(newState.phone) &&
        isValidPromoCode(newState.promoCode) && newState.payMethod === 'card') || (
          isValidEmail(newState.email) &&
          isValidPhone(newState.phone) &&
          isValidPromoCode(newState.promoCode) && newState.payMethod === 'bill' &&
          isValidPhone(newState.workPhone!) &&
          isValidINN(newState.inn!) &&
          isValidName(newState.companyName!) &&
          isValidName(newState.legalAddress!))
    });
    setScreenStateCard({
      ...newState,
      canPay: (isValidEmail(newState.email) &&
        isValidPhone(newState.phone) &&
        isValidPromoCode(newState.promoCode) &&
        newState.payMethod === 'card')
    })
  }, []);

  const handleBuyClick = useCallback(debounce(async () => {
    // withId(92179976)('reachGoal','pressBuyCorpSubscription')
    window.generalPixel.postClick('a2');
    const promoCode = await controller.buyPromoCode({
      ...screenStateCard,
    });

    setScreenState({...screenState, loading: true})

    if (!promoCode) {
      setScreenState({...screenState, loading: false})
      alert("Не получилось. Попробуйте ещё раз.");
      return;
    }

    if (screenState.payMethod === "card") {
      if (promoCode.paymentLink) {
        window.location.href = promoCode.paymentLink
      } else {
        setScreenState({...screenState, loading: false})
        alert("Не получилось сгенерировать форму для оплаты! Попробуйте ещё раз.");
      }
    } else {
      history.push("/manager_screen");
    }
  }, 2000, { leading: true, trailing: false }), [screenState])

  const handleActivateClick = useCallback(() => {
    domainRouter.go(config.codeRoute);
  }, []);

  // useEffect(() => {
  //   withId(92179976)('reachGoal', 'enteredCorp');
  // }, [withId])

  return (
    <div className="CorpSubRoot">
      <UpperCorporateBlock
        data={screenState}
        onDataChange={handleDataChange}
        onBuyClick={handleBuyClick}
      />
      <BottomMainBlock
        onActivateClick={handleActivateClick}
        isCorp
      />
      <Footer />
    </div>
  );
};

const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function isValidEmail(value: string): boolean {
  return EMAIL_REG.test(value);
}

function isValidPhone(value: string): boolean {
  if (value.length >= 4 && value.length <= 20) {
    return true;
  } else {
    return false;
  }
}

const INN_REG = /^[0-9]*$/;
function isValidINN(value: string): boolean {
  return INN_REG.test(value);
}

const PROMO_CODE_REG = /(^$)|(^[\dA-Z]{4,16}$)/;
function isValidPromoCode(value: string): boolean {
  return PROMO_CODE_REG.test(value);
}

function isValidName(value: string): boolean {
  if (value) {
    return true;
  } else {
    return false;
  };
}

export default CorporateSubscription;
